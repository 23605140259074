@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Futura LT W01 Bold";
	src: url("../public/fonts/Futura-LT-W01-Bold.woff2") format("woff2"), url("../public/fonts/Futura-LT-W01-Bold.woff") format("woff");
}

@font-face {
	font-family: "Futura-LT-W01-Medium";
	src: url("../public/fonts/Futura-LT-W01-Medium.woff") format("woff"), url("../public/fonts/Futura-LT-W01-Medium.woff2") format("woff2");
}

@font-face {
	font-family: "Graphik";
	src: url("../public/fonts/Graphik-Regular.woff2") format("woff2"), url("../public/fonts/Graphik-Regular.woff") format("woff"),
		url("../public/fonts/Graphik-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "GraphikBold";
	src: url("../public/fonts/GraphikSemibold.otf") format("opentype"), url("../public/fonts/GraphikSemibold.otf") format("opentype");
}

@font-face {
	font-family: "Tapeworm";
	src: url("../public/fonts/TAPEWORM.woff2") format("woff2"), url("../public/fonts/TAPEWORM.woff") format("woff"),
		url("../public/fonts/TAPEWORM.ttf") format("truetype");
}

.futura {
	font-family: "Futura LT W01 Bold", sans-serif;
}

.futura-med {
	font-family: "Futura-LT-W01-Medium", sans-serif;
}

.graphik {
	font-family: "Graphik", sans-serif;
}

.graphik-bold {
	font-family: "GraphikBold", sans-serif;
}

.tapeworm {
	font-family: "Tapeworm", sans-serif;
}

:root {
	--bg: hsl(0, 0%, 100%);
	--primary-color: #00af07;
	/* Anchor tag animation */
	--underline-width: 2px;
	--underline-block-width: 100px;
	--underline-color: hsla(122, 100%, 34%, 0.5);
	--underline-color-hover: hsla(122, 100%, 34%, 1);
	--underline-transition: 0.5s;
}

html {
	/* Prevent scrollbar from shifting the layout when it appears */
	margin-left: calc(100vw - 100%);
}

#footer {
	--underline-width: 1px;
}

/* Start animated link underlines */
.special-underline,
.fancy-underline,
main a:not(.bland) {
	color: var(--primary-color);
	background-image: linear-gradient(90deg, var(--bg), var(--bg)), linear-gradient(90deg, var(--underline-color), var(--underline-color));
	background-size: var(--underline-block-width) var(--underline-width), 100% var(--underline-width);
	background-repeat: no-repeat;
	background-position-x: calc(var(--underline-block-width) * -1), 0;
	background-position-y: 100%;
	transition: background-position-x var(--underline-transition);
	padding-bottom: var(--underline-width);
}

.special-underline {
	background-image: linear-gradient(90deg, var(--bg), var(--bg)), linear-gradient(90deg, var(--bg), var(--bg));;
}

.special-underline:hover,
.fancy-underline:hover,
main a:not(.bland):hover {
	background-image: linear-gradient(90deg, var(--bg), var(--bg)), linear-gradient(90deg, var(--underline-color-hover), var(--underline-color-hover));
	background-position-x: calc(100% + var(--underline-block-width)), 0;
}
/* End Animated link underlines */

aside.svelte-rntogh {
	/* Wallet selection panel is on top */
	z-index: 99;
}

/* Adds an animated ellipsis at the end of text */
.loading:after {
	overflow: hidden;
	display: inline-block;
	vertical-align: bottom;
	-webkit-animation: ellipsis steps(4, end) 900ms infinite;
	animation: ellipsis steps(4, end) 900ms infinite;
	content: "\2026"; /* ascii code for the ellipsis character */
	width: 0px;
}

@keyframes ellipsis {
	to {
		width: 1.25em;
	}
}

@-webkit-keyframes ellipsis {
	to {
		width: 1.25em;
	}
}
/* End animated ellipsis */